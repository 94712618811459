<template >
    <div>

        <div class="app-modal__in">
            <div
                class="app-modal__header d-flex f-between p-5 m-title-modal"
            >
                <p class="large--title m-0">
						{{
							$t("message.new_m", {
                			m: $t("message.project"),
              			})
						}}
                </p>
                <div>
                    <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'store'"
                        :loading="loadingButton"
                        @c-submit="submit(true)"
                        @c-close="close()"
                    ></crm-store-update-close>
                </div>
            </div>
        </div>
        <!-- app-modal__header end -->

        <div class="app-modal__body p-5 pt-3 pb-0">
            <el-form ref="form" :model="form" :rules="rules">
                <div class="timeline-items__right rounded-sm w-100 p-3 mb-5">
                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <el-form-item :label="$t('message.project_name')" prop="name">
                                <el-input v-model="form.name" :placeholder="$t('message.project_name')"></el-input>
                            </el-form-item>
                        </el-col>
                        <!-- end-col -->
                    </el-row>
                </div>


                    <el-row :gutter="20">
                        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
                            <el-tabs type="border-card" v-model="activeName">
                                <el-tab-pane name="first">
                                    <span slot="label"><i class="fa-regular fa-calendar-xmark"></i> </span>
                                    <div style="padding: 11px 0px;">
                                       {{ $t('message.project without a deadline') }}
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane name="second">
                                    <span slot="label">
                                        <i class="fa-regular fa-calendar-plus"></i>
                                        {{ $t('message.term') }}
                                    </span>
                                    <div>
                                        <el-date-picker
                                            class="w-100"
                                            v-model="term_date"
                                            type="date"
                                            :placeholder="$t('message.term')"
														  :format="date_format"
                        						  :value-format="date_format"
														  >
                                        </el-date-picker>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane name="thrid">
                                    <span slot="label">
                                        <i class="fa-regular fa-calendar-days"></i>
                                        {{ $t('message.start') }}-{{$t('message.finish')}}
                                    </span>
                                    <div>
                                        <el-date-picker
                                            class="w-100"
                                            v-model="start_finish_date"
                                            type="daterange"
                                            align="right"
                                            unlink-panels
                                            :range-separator="$t('message.to')"
                                            :start-placeholder="$t('message.start')"
                                            :end-placeholder="$t('message.finish')"
														  :format="date_format"
                        						  :value-format="date_format"
                                            >
                                        </el-date-picker>
													 <!-- :picker-options="pickerOptions" -->
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </el-col>
                        <!-- end-col -->
                        <el-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
                            <el-tabs type="border-card">
                                <el-tab-pane>
                                    <span slot="label">{{ $t('message.plan') }}</span>
                                    <div>
                                        <el-row :gutter="20">
                                            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                <el-form-item class="mb-0">
                                                    <el-input
                                                        v-model="form.plan_hour"
                                                        @keypress.native="onlyNumber($event)" :placeholder="$t('message.hours')">
                                                        <template slot="append" ><i class="fa-regular fa-clock"></i></template>
                                                    </el-input>
                                                </el-form-item>
                                            </el-col>
                                            <!-- end-col -->
                                            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                <el-form-item class="mb-0">
												    <crm-money-input
                                                        v-model="form.price"
                                                        :old="form.price"
                                                        size="large">
                                                    </crm-money-input>
                                                </el-form-item>
                                            </el-col>
                                            <!-- end-col -->
											<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                <el-form-item class="mb-0">
													<select-currency
                                                        v-model="form.currency_id"
                                                        :id="form.currency_id"
                                                        size="large" @input="changeCurrency">
                                                    </select-currency>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </el-col>
                        <!-- end-col -->
                    </el-row>

                    <div class="style-home">
                        <el-card class="box-card mt-4 mb-4 tom">
                            <el-row :gutter="20">
                                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                    <el-form-item  :label="$t('message.project_manager')" prop="leader_ids">
                                    <multi-select-users
                                        v-model="form.leader_ids"
                                        :user_ids="form.leader_ids"
                                        :placeholder="$t('message.project_manager')"
                                        size="large"
                                    ></multi-select-users>
                                    </el-form-item>
                                    <el-form-item :label="$t('message.type_of_project_work')">
                                        <multi-select-type-of-project-works
                                            v-model="form.type_ids"
                                            :type_ids="form.type_ids"
                                            size="large"
                                        ></multi-select-type-of-project-works>
                                    </el-form-item>
                                </el-col>

                                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                                    <el-form-item  :label="$t('message.responsible')">
                                        <multi-select-users
                                            v-model="form.responsible_ids"
                                            :user_ids="form.responsible_ids"
                                            :placeholder="$t('message.responsible')"
                                            size="large"
                                            :collapse_tags="true"
                                        ></multi-select-users>
                                    </el-form-item>
                                    <el-form-item  :label="$t('message.filials')" prop="filial_ids">
                                        <multi-select-filials
                                            v-model="form.filial_ids"
                                            :filial_ids="form.filial_ids"
                                            size="large"
                                        ></multi-select-filials>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-card>
                    </div>

                    <el-row :gutter="20"  class="style-home">
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <el-card class="box-card mb-4 tom">
                                <div slot="header" class="clearfix d-flex">
                                    <span class="flex-1">
                                        <el-breadcrumb separator="/">
                                            <el-breadcrumb-item> <b>{{ $t('message.access_rights') }}</b></el-breadcrumb-item>
                                            <!-- <el-breadcrumb-item> <b>1</b> team</el-breadcrumb-item> -->
                                            <el-breadcrumb-item> <b>{{ filtered_users.length }}</b> {{ $t('message.participants') }} </el-breadcrumb-item>
                                        </el-breadcrumb>
                                    </span>
                                    <el-button
                                        @click="centerDialogVisible = true"
                                        style="float: right; padding: 3px 0"
                                        type="text">
                                        <i class="fa-solid fa-user-plus"></i>
                                    </el-button>
                                </div>
                                <div class="person-project">
                                    <div class="itme-person-project" v-for="(user, index) in filtered_users" :key="index">
                                       <el-checkbox v-model="user.id" disabled>
														<img v-if="user.avatar" :src="user.avatar" alt="" class="task_user_give_img">
														<img v-else src="/img/flag/employee.svg" alt="" class="task_user_give_img">
														<span class="person-name"> {{ user.name }} </span>
                                       </el-checkbox>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                        <!-- end-col -->
                    </el-row>

            </el-form>
        </div>
        <!-- end app-modal__body -->


        <el-dialog
            title="Warning"
            :visible.sync="centerDialogVisible"
            width="40%"
            class="uni-style-dialog"
            :append-to-body="true"
            center
				ref="accessDialog"
				@opened="dialogOpened('accessDialogChild')"
				@closed="dialogClosed('accessDialogChild')"
				>
            <Person
					ref="accessDialogChild"
					dialog="accessDialog"
					@set-filter-users="addUsers"
				>
				</Person>
        </el-dialog>
		  <!-- ref="accessDialogChild" dialog="accessDialog" -->
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import project from "@/utils/mixins/models/project";
import Person from "./person";

export default {
    mixins: [form, drawer, project],
	components: {
		Person,
  	},
    data() {
      return {
			filtered_users: [],
			activeName: 'first',
			term_date: '',
			start_finish_date: '',
			clear_users: false
      };
    },

    computed: {
		...mapGetters({
			authUser: 'auth/user',
		})
    },
	 watch:{
		'activeName': {
			handler: function (newVal){
				if (newVal == 'first') {
					this.form.date_status = 1;
					this.term_date = '';
					this.start_finish_date = '';
				}
				else if (newVal == 'second'){
					this.start_finish_date = '';
				}
				else if (newVal == 'thrid') {
					this.term_date = '';
				}
			},
			deep: true
		},

		'term_date': {
			handler: function (newVal){
				this.form.begin_date = this.formatDateNow;
				this.form.end_date = newVal;
				this.form.date_status = 2;
			},
			deep: true
		},
		'start_finish_date': {
			handler: function (newVal){
				this.form.begin_date = newVal[0];
				this.form.end_date = newVal[1];
				this.form.date_status = 3;
			},
			deep: true
		}
	 },
    methods: {
		...mapActions({
			save: 'projects/store'
		}),
		afterOpen(){
			this.form = JSON.parse(JSON.stringify(this.model));
			this.form.leader_ids.push(this.authUser.id);

		},
 		submit(close = true) {
			let users = [];
			if (!_.isEmpty(this.form.leader_ids)) {
				this.form.leader_ids.forEach(user_id => {
					users.push({
						user_id: user_id,
						status: 'leader'
					});
				});
			}
			if (!_.isEmpty(this.form.responsible_ids)) {
				this.form.responsible_ids.forEach(user_id => {
					users.push({
						user_id: user_id,
						status: 'responsible'
					});
				});
			}

			if (!_.isEmpty(this.filtered_users)) {
				this.filtered_users.forEach(item => {
					users.push({
						user_id: item.id,
						status: 'participant'
					});
				});
			}
			this.form['users'] = users;

         this.$refs["form"].validate((valid) => {
				if (valid) {
					this.loadingButton = true;
					this.save(this.form)
						.then((res) => {
							this.loadingButton = false;
							this.$alert(res);
							this.parent().listChanged();
							if (close) this.close();
						})
						.catch((err) => {
							this.loadingButton = false;
							this.$alert(err);
						});
				}
          });
      },
		dialogOpened(ref){
			if (this.$refs[ref]){
				if (_.isFunction(this.$refs[ref].afterOpen)) {
					this.$refs[ref].afterOpen();
				}
			}
		},
		dialogClosed(ref){
			if (this.$refs[ref]){
				if (_.isFunction(this.$refs[ref].afterLeave)) {
					this.$refs[ref].afterLeave();
				}
			}
		},
		closeDialog(){
			this.centerDialogVisible = false;
		},
		  addUsers(users){
			this.filtered_users = users;
		  },
		   onlyNumber(e){
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[0-9]+$/.test(char)) return true; // Match with regex
            else e.preventDefault(); // If not match, don't add to input text
        },
		beforeLeave(){
			if (!_.isEmpty(this.$refs['accessDialog'].$children) ){
				this.$refs['accessDialog'].$children[0].users = [];
			}
		},
		afterLeave(){
			this.$store.commit('projects/EMPTY_MODEL');
			this.filtered_users = [];
			this.term_date = '';
			this.start_finish_date = '';
			this.activeName = 'first';
		  }
    },
};
</script>
